<template>
  <div class="container">
    <div class="copy-item" v-for="(item,index) in datalist" :key="index">
      <div class="subtitle">文案{{index + 1}}:</div>
      <van-field v-model="item.msg" rows="5" autosize type="textarea" placeholder="请输入" />
      <van-icon name="add-o" size="26" v-if="index === datalist.length - 1" @click="handlePlus()" />
      <van-icon name="minus" size="26" v-else @click="handleMinus(index)" />
    </div>
    <div class="operate">
      <van-button type="info" round @click="onSubmit">提交</van-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Copywriting",
  data() {
    return {
      message: '',
      datalist: [],
      productId: ''
    }
  },
  created() {
    let { id } = this.$route.params;
    this.productId = id;
    this.get(`/Base/PublicTools/GetRabbitShareContent`, { productId: id }, 2).then(res => {
      if (res.code === 1) {
        let list = res.response.list || [];
        this.datalist = list.map(it => {
          return { msg: it.shareContent }
        });
        if (this.datalist.length === 0) {
          this.datalist.push({ msg: '' });
        }
      } else {
        this.$dialog.alert({ title: "提示", message: "没找到商品" }).then(() => {
          this.$router.push("/");
        })
      }
    })
  },
  methods: {
    handlePlus() {
      this.datalist.push({ msg: '' });
    },
    handleMinus(index) {
      this.datalist.splice(index, 1);
    },
    onSubmit() {
      let idx = this.datalist.findIndex(it => !it.msg);
      if (idx >= 0) return this.$toast(`请输入文案${idx + 1}的内容`)
      this.post(`/Base/PublicTools/AddRabbitShareContent?`, {
        productId: this.productId,
        msg: JSON.stringify(this.datalist.map(it => it.msg))
      },true, 2).then(res => {
        if (res.code === 1) {
          this.$toast("上传成功!");
        } else { 
          this.$toast(res.msg);
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding: 11px 15px 60px;

  .copy-item {
    position: relative;
    margin-bottom: 10px;

    .subtitle {
      font-size: 16px;
      padding-bottom: 10px;
    }

    .van-field {
      border-radius: 8px;
      border: 1px solid #ebedf0;

      &:after {
        border: none;
      }
    }

    .van-icon {
      position: absolute;
      top: 0;
      right: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .operate {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 11px 15px;
    background-color: #fff;

    .van-button {
      width: 100%;
    }
  }
}
</style>